import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListMoyensPaiementComponent } from './list-moyens-paiement.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PaiementComponent } from './paiement.component';
import { RecuComponent } from './recu.component';



@NgModule({
  declarations: [ListMoyensPaiementComponent, PaiementComponent,
    RecuComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'liste-moyens',
        component: ListMoyensPaiementComponent
      },
      {
        path: 'confirmer',
        component: PaiementComponent
      },
      {
        path: 'recu',
        component: RecuComponent
      }
    ])
  ]
})
export class PaiementModule { }
