import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  nom: string;
  prenom: string;
  email: string;
  phone: string;
  code: string;
  codeConfirm: string;
  password: string;
  passwordConfirm: string;
  init: boolean;
  waitingConfirm: boolean;
  setPassword: boolean;
  lds: boolean;

  constructor(protected authService: AuthService, protected router: Router, protected toastr: ToastrService) { }

  ngOnInit(): void {
    this.init = true;
    this.lds = false;
  }

  register() {
    this.lds = true;
    this.waitingConfirm = false;
    const data = new FormData();
    data.append('code', this.code);
    data.append('telephone', this.phone);
    this.authService.registerItechpayMarchandConfirm(data).subscribe(res => {
      if (res.body.status !== 'success') {
        this.toastr.error(res.body.data.message, 'Inscription');
        this.lds = false;
        this.waitingConfirm = true;
      } else {
        this.toastr.success('Votre compte a été crée avec succès !', 'Inscription');
        this.authService.jwtLogin({
          username: this.phone,
          password: this.password
        }).subscribe(() => {
          this.router.navigate(['/accueil']);
        }, err => {
          console.error(err);
          this.lds = false;
        });
      }
    }, err => {
      console.error(err);
    });
  }

  ressendCode() {
    this.lds = true;
    this.waitingConfirm = false;
    const data = new FormData();
    data.append('telephone', this.phone);
    this.authService.resendCode(data).subscribe(res => {
      if (res.body.status !== 'success') {
        this.toastr.error(res.body.data.message, 'Inscription');
        this.lds = false;
        this.waitingConfirm = true;
      } else {
        this.waitingConfirm = true;
        this.toastr.success('Le code de confirmation a été renvoyé', 'Inscription');
        this.lds = false;
      }
    }, err => {
      this.lds = false;
      this.waitingConfirm = true;
      console.error(err);
    });
  }

  getConfirmCode() {
    if (this.password !== this.passwordConfirm) {
      this.toastr.error('Les deux mot de passe ne sont pas identiques, veuillez réessayer', 'Inscription');
    } else {
      this.lds = true;
      this.init = false;
      this.authService.registerItechpayMarchand(this.parseFormData()).subscribe(res => {
        if (res.body.status != 'success') {
          this.toastr.error(res.body.message, 'Inscription');
          this.lds = false;
          this.init = true;
        } else {
          this.toastr.success(res.body.data.message, 'Confirmation');
          this.lds = false;
          this.init = false;
          this.waitingConfirm = true;
        }
      }, err => {
        this.toastr.error("Une erreur s'est produite lors de l'inscription, veuillez réessayer", 'Inscription');
        console.error(err);
        this.lds = false;
        this.init = true;
      });
    }
  }

  confirm() {
    // if (moment().diff(moment(this.dateNaissance, 'YYYY-MM-DD'), 'years') >= AGE_LIMITE) {
    //   this.lds = true;
    //   this.init = false;
    //   this.getConfirmCode(this.phone);
    // } else {
    //   this.toastr.error("Vous n'avez pas atteint l'âge minimum requis pour vous inscrire sur Eregistration !", 'Erreur');
    // }
  }

  parseFormData(): FormData {
    const data = new FormData();
    data.append('nom', this.nom);
    data.append('prenom', this.prenom);
    data.append('email', this.email);
    data.append('telephone', this.phone);
    data.append('username', this.phone);
    data.append('password', this.password);
    return data;
  }

}
