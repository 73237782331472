<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top" [ngClass]="{'opac': opac }">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="assets/img/itechpay_picture.png" width="113px" height="34px" alt="PAYAGG">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngIf="isAuthenticated">
          <div class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownAccount" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" style="font-weight: 700;">{{ currentUser?.nom }}
              {{ currentUser?.prenom }}
              ({{ currentUser?.username }})&nbsp;</a>
            <ul class="dropdown-menu float-right" aria-labelledby="dropdownAccount">
              <!-- <li style="cursor: pointer;"><a class="dropdown-item" href="#">Profil</a></li>
              <li style="cursor: pointer;"><a class="dropdown-item" (click)="dashboard()">Tableau de bord</a></li> -->
              <li style="cursor: pointer;"><a class="dropdown-item" (click)="logout()">Déconnexion</a></li>
            </ul>
          </div>
        </li>
        <!--li class="nav-item login-btn" *ngIf="!isAuthenticated">
          <a class="nav-link text-center" style="cursor: pointer;" (click)="register()">Inscription</a>
        </li-->
        <li class="nav-item login-btn" *ngIf="!isAuthenticated">
          <a class="nav-link text-center" style="cursor: pointer;" (click)="login()">Connexion</a>
        </li>
        <!-- <li class="nav-item login-btn" *ngIf="!isAuthenticated">
          <a class="nav-link text-center" style="cursor: pointer;" (click)="faq()">Comment ça marche?</a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>