import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../shared/app.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OperatorService {

    public resourceUrl = SERVER_API_URL + 'api/operators';
    donneePaiement: any;
    operatorChoice: number;

    constructor(protected http: HttpClient) { }

    create(value: FormData): Observable<any> {
        return this.http.post<any>(`${this.resourceUrl}`, value, { observe: 'response' });
    }

    query(): Observable<any> {
        return this.http.get<any>(`${this.resourceUrl}`, { observe: 'response' });
    }

    queryNotArchived(): Observable<any> {
        return this.http.get<any>(`${this.resourceUrl}/false/archived`, { observe: 'response' });
    }

}
