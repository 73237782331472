import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resset-password-confirm',
  templateUrl: './reset-password-confirm.component.html'
})
export class RessetPasswordConfirmComponent implements OnInit {

  code: string;
  password: string;
  confirmPassword: string;
  returnUrl: string;
  loading: boolean;

  constructor(private auth: AuthService,
    private route: ActivatedRoute,
    protected router: Router, protected toastr: ToastrService) { }

  ngOnInit(): void {
    // reset login status
    this.auth.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/paiements/liste-moyens';
  }

  confirm() {
    this.loading = true;
    const data = new FormData();
    data.append('username', this.auth.getRessetUser());
    data.append('code', this.code);
    data.append('password', this.password);
    data.append('confirmPassword', this.confirmPassword);
    this.auth.ressetPasswordConfirm(data).subscribe(res => {
      this.loading = false;
      console.log(res);
      if (res.body.status === 'success') {
        this.toastr.success("Votre mot de passe à été réinitialisé avec succès, veuillez vous connecter à nouveau!", 'Mot de passe');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.body.message, 'Mot de passe');
      }
    }, err => {
      this.loading = false;
      console.error(err);
      this.toastr.error("Une erreur est survénue, veuillez réessayer", 'Mot de passe');
    });
  }

}
