import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth-service';
import { ToastrService } from 'ngx-toastr';
import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { TransactionService } from '../transaction/transaction.service';
import { IOperator } from '../operator/operator.model';
import { ITransaction } from '../transaction/transaction.model';
import { IPaymentLink } from '../payment-link/payment-link.model';


@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html'
})
export class PaiementComponent implements OnInit {
  loading: boolean;
  modalLoading: boolean;
  telephone: string;
  montant: string;
  marchand: string;
  description: string;
  returnUrl: string;
  currentUser: string;
  operator: IOperator;
  transaction: ITransaction;
  link: IPaymentLink;
  error: boolean;
  public readonly LOGGED_IN_USER = 'LOGGED_IN_USER';
  public readonly JWT_TOKEN = 'JWT_TOKEN';

  constructor(private auth: AuthService, private route: ActivatedRoute, protected router: Router,
    protected toastr: ToastrService, @Inject(SESSION_STORAGE) private localStorage: StorageService,
    private location: Location, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/accueil';
    this.currentUser = this.localStorage.get(this.LOGGED_IN_USER);
    this.marchand = this.currentUser;
    this.operator = this.transactionService.getOperator();
    this.link = this.transactionService.getSelfPay();
    if (this.link) {
      //console.log(this.link);
      this.montant = this.link.amount + '';
      this.description = this.link.description;
      this.marchand = this.link.marchand;
    }
    if (!this.operator) {
      this.toastr.error("Veuillez d'abord choisir un moyen de paiement", 'Paiement');
      this.router.navigate(['/paiements/liste-moyens']);
    }
  }

  submit() {
    this.loading = true;
    const data = new FormData();
    data.append('numero', this.telephone);
    data.append('montant', this.montant);
    data.append('marchand', this.marchand);
    data.append('operator', this.operator.label);
    data.append('description', this.description);
    this.transactionService.create(data).subscribe(res => {
      if (res.body.status == 'success') {
        this.transaction = res.body.data;
        this.router.navigate([this.returnUrl]);
        this.loading = false;
        this.toastr.success("Transaction enregistrée avec succès", 'Transaction');
      } else {
        this.toastr.error(res.body.message, 'Soumettre');
        this.loading = false;
      }
    }, err => {
      console.error(err);
      this.toastr.error("Erreur lors de l'envoi de la requete. Veuillez ressayer", 'Soumettre');
      this.loading = false;
    });
  }

  getOperatorLogo() {
    return "assets/img/" + this.operator.label + "_LOGO.png"
  }

  getQrcode(): void {
    this.modalLoading = true;
    const data = new FormData();
    data.append('numero', this.telephone);
    data.append('montant', this.montant);
    data.append('marchand', this.currentUser);
    data.append('operator', this.operator.label);
    data.append('description', this.description);
    this.transactionService.create(data).subscribe(res => {
      if (res.body.status == 'success') {
        this.transaction = res.body.data;
        this.modalLoading = false;
        this.error = false;
      } else {
        this.toastr.error(res.body.message, 'Soumettre');
        this.modalLoading = false;
        this.error = true;
      }
    }, err => {
      console.error(err);
      this.toastr.error("Erreur lors de l'envoi de la requete. Veuillez ressayer", 'Soumettre');
      this.modalLoading = false;
      this.error = true;
    });
  }

  check(transId: string) {
    this.loading = true;
    this.transactionService.check(transId).subscribe(res => {
      if (res.data.statut !== 'CONFIRMED') {
        this.toastr.error("Transaction non confirmée", 'Transaction');
        this.cancel();
      } else {
        this.toastr.success("Transaction confirmée", 'Transaction');
        this.cancel();
      }
      this.loading = false;
    }, err => {
      console.error(err);
      this.loading = false;
      this.toastr.error("Erreur lors de la vérification du paiement", 'Transaction');
      this.cancel();
    });
  }

  cancel() {
    this.router.navigate(['/dashboard']);
  }

}
