<app-navigation [opac]="true"></app-navigation>
<header>
    <div class="header-item">
        <div class="container">
            <div class="row title-block">
                <div class="col-xs-12 col-md-6">
                    <h4 class="title"><b>Demande de paiement par {{ operator?.name }}</b></h4>
                </div>
                <div class="col-xs-12 col-md-6">
                    <div class="stepwizard">
                        <div class="stepwizard-row">
                            <div class="stepwizard-step">
                                <button type="button" class="btn btn-default btn-circle">1</button>
                            </div>
                            <div class="stepwizard-step">
                                <button type="button" class="btn btn-primary btn-circle">2</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="bg-grey dashboard">
    <ng-container *ngIf="loading">
        <div class="loading">
            <div class="lds-dual-ring"></div>
        </div>
    </ng-container>
    <div class="container" *ngIf="!loading">
        <div class="row justify-content-center">
            <div class="col-md-8 col-sm-12">
                <div class="card type-card">
                    <div class="card-body">
                        <div class="text-center">
                            <img [src]="getOperatorLogo()" alt="Partenaire logo" class="pay-cap">
                            <br />
                            <br />
                        </div>
                        <form class="paiement-form" ngNativeValidate #paiementForm>
                            <div *ngIf="link">
                                <h5 class="text-center">Confirmez le paiement de {{ link?.amount }} FCFA au marchand
                                    {{ link?.marchand }} pour
                                    {{ link?.description }}</h5>
                            </div>
                            <div class="form-label-group">
                                <input type="text" id="telephone" name="telephone" [(ngModel)]="telephone"
                                    class="form-control" required autofocus>
                                <label for="telephone">Numéro de Compte</label>
                            </div>
                            <ng-container *ngIf="!link">
                                <div class="form-label-group">
                                    <input type="number" id="montant" name="montant" [(ngModel)]="montant"
                                        class="form-control" required>
                                    <label for="montant">Montant à Payer</label>
                                </div>
                                <div class="form-label-group">
                                    <input type="text" id="description" name="description" [(ngModel)]="description"
                                        class="form-control" required>
                                    <label for="description">Description ou Référence</label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="operator.label === 'ITECHPAY'">
                                <button (click)="getQrcode()" [disabled]="!paiementForm.checkValidity()"
                                    class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                    type="button" data-toggle="modal" data-target="#qrcodeModal"
                                    data-backdrop="static">Soumettre</button>
                            </ng-container>
                            <ng-container *ngIf="operator.label !== 'ITECHPAY'">
                                <button (click)="submit()" [disabled]="!paiementForm.checkValidity()"
                                    class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                    type="submit">Soumettre</button>
                            </ng-container>
                            <button (click)="cancel()"
                                class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                type="reset">Annuler</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
<!-- Modal -->
<div class="modal fade" id="qrcodeModal" tabindex="-1" role="dialog" aria-labelledby="linkFormModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="linkFormModalLabel"><b>Scanner le code QR pour payer</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="modalLoading">
                    <div class="loading">
                        <div class="lds-dual-ring"></div>
                    </div>
                </ng-container>
                <div class="text-center" *ngIf="!modalLoading">
                    <img [src]="transaction?.message" alt="qrcode" class="img-responsive" width="200" height="200">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
                <button type="submit" class="btn btn-primary" data-dismiss="modal"
                    (click)="(error) ? getQrcode() : check(transaction.transactionId)">Confirmer</button>
            </div>
        </div>
    </div>
</div>