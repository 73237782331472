import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resset-password',
  template: `
<section class="full-height">
    <div class="container align-items-center">
        <div class="row">
            <div class="col-lg-10 col-xl-9 mx-auto">
                <div class="card card-signin flex-row my-5">
                    <div class="card-img-left d-none d-md-flex">
                        <div class="container align-items-center">
                            <div class="row">
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap" style="margin-right: 14px;">
                                            <span class="mbr-iconfont fa-shield fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Plateforme
                                                sécurisée</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Effectuez vos
                                                transactions en toute sécurité</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-money fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Moyens de
                                                paiement divers</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nous intégrons plusieurs
                                                moyens de paiement</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-code fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">
                                                Intégration rapide</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nos APIs sont simples et
                                                faciles à utiliser</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-5">
                    <div class="container">
                  <ng-container *ngIf="loading">
                      <h5 class="text-center">Connexion en cours...</h5>
                      <div class="loading">
                          <div class="lds-dual-ring"></div>
                      </div>
                  </ng-container>
                  <div class="row" *ngIf="!loading">
                      <div class="col-md-9 col-lg-8 mx-auto">
                          <h3 class="login-heading mb-2 text-center" style="font-weight: bold;">Réinitialisation de mot de passe</h3>
                          <hr class="underline" />
                          <form class="login-form">
                              <div class="form-label-group">
                                  <input type="text" id="username" id="username" name="username"
                                      [(ngModel)]="username" class="form-control" required autofocus>
                                  <label for="username">Téléphone ou email</label>
                              </div>
                              <button (click)="resset()"
                                  class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                  type="submit">Réinitialiser</button>
                          </form>
                      </div>
                  </div>
              </div>
              </div>
                </div>
            </div>
        </div>
    </div>
</section>
  `
})
export class RessetPasswordComponent implements OnInit {

  username: string;
  loading: boolean;

  constructor(private auth: AuthService,
    private route: ActivatedRoute,
    protected router: Router, protected toastr: ToastrService) { }

  ngOnInit(): void {
    this.auth.logout();

  }

  resset() {
    this.loading = true;
    const data = new FormData();
    data.append('username', this.username);
    this.auth.ressetPassword(data).subscribe(res => {
      this.loading = false;
      console.log(res);
      if (res.body.status === 'success') {
        this.auth.setRessetUser(this.username);
        this.router.navigate(['resset-password-confirm']);
      } else {
        this.toastr.error(res.body.message, 'Mot de passe');
      }
    }, err => {
      this.loading = false;
      console.error(err);
      this.toastr.error("Une erreur est survénue, veuillez réessayer", 'Mot de passe');
    });
  }

}
