import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../shared/app.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaiementService {

  public resourceUrl = SERVER_API_URL + 'api/epayments';
  donneePaiement: any;

  constructor(protected http: HttpClient) { }

  payer(value): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}`, value, { observe: 'response' });
  }

  informationsPaiement(): Observable<any> {
    return this.http.get<any>(`${SERVER_API_URL}api/payments/infos`, { observe: 'response' });
  }

  setDonneePaiement(value) {
    this.donneePaiement = value;
  }

  getDonneePaiement() {
    return this.donneePaiement;
  }

}
