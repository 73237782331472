<section class="full-height" style="padding-top: 0;">
    <div class="container align-items-center">
        <div class="row">
            <div class="col-lg-10 col-xl-9 mx-auto">
                <div class="card card-signin flex-row my-5">
                    <div class="card-img-left d-none d-md-flex">
                        <div class="container align-items-center">
                            <div class="row">
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap" style="margin-right: 14px;">
                                            <span class="mbr-iconfont fa-shield fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Plateforme
                                                sécurisée</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Effectuez vos
                                                transactions en toute sécurité</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-money fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Moyens de
                                                paiement divers</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nous intégrons plusieurs
                                                moyens de paiement</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-code fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">
                                                Intégration rapide</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nos APIs sont simples et
                                                faciles à utiliser</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body align-items-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mx-auto">
                                    <h3 class="login-heading mb-2 text-center" style="font-weight: bold;">Inscription
                                    </h3>
                                    <hr class="underline" />
                                    <form class="register-form" (ngSubmit)="getConfirmCode()">
                                        <ng-container *ngIf="init">
                                            <div class="form-label-group">
                                                <input type="text" id="nom" name="nom" [(ngModel)]="nom"
                                                    class="form-control" required>
                                                <label for="nom">Nom</label>
                                            </div>
                                            <div class="form-label-group">
                                                <input type="text" id="prenom" name="prenom" [(ngModel)]="prenom"
                                                    class="form-control" required>
                                                <label for="prenom">Prénom</label>
                                            </div>
                                            <div class="form-label-group">
                                                <input type="email" id="email" name="email" [(ngModel)]="email"
                                                    class="form-control">
                                                <label for="email">e-mail</label>
                                            </div>
                                            <div class="form-label-group">
                                                <input type="text" id="phone" name="phone" [(ngModel)]="phone"
                                                    class="form-control" required autofocus>
                                                <label for="phone">N° de Téléphone</label>
                                            </div>
                                            <div class="form-label-group">
                                                <input type="password" id="password" name="password"
                                                    [(ngModel)]="password" class="form-control" required>
                                                <label for="password">Mot de passe</label>
                                            </div>
                                            <div class="form-label-group">
                                                <input type="password" id="passwordConfirm" name="passwordConfirm"
                                                    [(ngModel)]="passwordConfirm" class="form-control" required>
                                                <label for="passwordConfirm">Confirmer le mot de passe</label>
                                            </div>
                                            <button [disabled]="!prenom || !phone || !password || !passwordConfirm"
                                                class="btn btn-lg btn-info btn-block btn-login text-uppercase font-weight-bold mb-2"
                                                type="button" (click)="getConfirmCode()">Continuer</button>
                                        </ng-container>
                                        <ng-container *ngIf="lds">
                                            <div class="loading">
                                                <p>Génération du code de confirmation</p>
                                                <div class="lds-dual-ring"></div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="waitingConfirm">
                                            <p>Nous avons envoyé un code de confirmation au numéro {{ phone }}. Veuillez
                                                saisir
                                                ce code</p>
                                            <div class="form-label-group">
                                                <input type="text" id="code" name="code" [(ngModel)]="code"
                                                    class="form-control" required>
                                                <label for="code">Code de confirmation</label>
                                            </div>
                                            <button [disabled]="!code"
                                                class="btn btn-lg btn-info btn-block btn-login text-uppercase font-weight-bold mb-2"
                                                type="submit" (click)="register()">Confirmer l'inscription</button>
                                            <div class="" *ngIf="!lds">
                                                <p class="small"><a href="JavaScript:void(0);" class="color-primary"
                                                        (click)="ressendCode()">Je n'ai pas reçu le code</a></p>
                                            </div>
                                        </ng-container>
                                        <div class="" *ngIf="!lds && !waitingConfirm">
                                            <p class="small">J'ai déjà un compte <a href="#" class="color-primary"
                                                    routerLink="/login"> Me
                                                    connecter</a></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>