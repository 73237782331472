import { Component, OnInit } from '@angular/core';
import { PaiementService } from './paiement.service';

@Component({
  selector: 'app-recu',
  templateUrl: './recu.component.html',
  styleUrls: ['./recu.component.css']
})
export class RecuComponent implements OnInit {

  donnees: any;
  
  constructor(private paiementService: PaiementService) { }

  ngOnInit(): void {
    this.donnees = this.paiementService.getDonneePaiement();
  }

}
