export const TOKEN_TTL = 86399;

// export const SERVER_API_URL =  'http://127.0.0.1:8000/';
// export const SERVER_UPLOADS_URL = 'http://localhost/apipay/public/index.php/uploads';
// export const BASE_RETURN_URL = 'http://localhost/apipay/public/index.php/confirm_paiement/';
// export const APP_URL = 'http://localhost:4200';

// export const SERVER_API_URL = 'http://apipayapp.itechcenter.ne/index.php/';
// export const SERVER_UPLOADS_URL = 'http://apipayapp.itechcenter.ne/index.php/';
// export const BASE_RETURN_URL = 'http://apipay.itechcenter.ne/';
// export const APP_URL = 'http://apipay.itechcenter.ne';

export const SERVER_API_URL = 'https://apipayapp.itechcenter.ne/index.php/';
export const SERVER_UPLOADS_URL = 'https://apipayapp.itechcenter.ne/index.php/';
export const BASE_RETURN_URL = 'https://apipay.itechcenter.ne/';
export const APP_URL = 'https://apipay.itechcenter.ne';
