import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../shared/app.constants';
import { Observable } from 'rxjs';
import { IOperator } from '../operator/operator.model';
import { IPaymentLink } from '../payment-link/payment-link.model';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    public resourceUrl = SERVER_API_URL + 'api/transactions';
    public disburseUrl = SERVER_API_URL + 'api/disbursements';
    donneePaiement: any;
    operatorChoice: IOperator;
    selfPay: IPaymentLink;

    constructor(protected http: HttpClient) { }

    create(value: FormData): Observable<any> {
        return this.http.post<any>(`${this.resourceUrl}`, value, { observe: 'response' });
    }

    check(value: string): Observable<any> {
        return this.http.post<any>(`${this.resourceUrl}/${value}/checks`, { observe: 'response' });
    }

    query(): Observable<any> {
        return this.http.get<any>(`${this.resourceUrl}`, { observe: 'response' });
    }

    queryByMarchand(marchand: string): Observable<any> {
        return this.http.get<any>(`${this.resourceUrl}/${marchand}/marchand`, { observe: 'response' });
    }

    setOperator(label: IOperator) {
        this.operatorChoice = label;
    }

    getOperator() {
        return this.operatorChoice;
    }

    setSelfPay(link: IPaymentLink) {
        this.selfPay = link;
    }

    getSelfPay() {
        return this.selfPay;
    }

    refund(value: string): Observable<any> {
        return this.http.post<any>(`${this.resourceUrl}/${value}/refund`, { observe: 'response' });
    }

    disburse(value: FormData): Observable<any> {
        return this.http.post<any>(`${this.disburseUrl}`, value, { observe: 'response' });
    }

    checkDisbursement(value: string): Observable<any> {
        return this.http.post<any>(`${this.disburseUrl}/${value}/check`, { observe: 'response' });
    }

    disbursementByMarchand(marchand: string): Observable<any> {
        return this.http.get<any>(`${this.disburseUrl}/${marchand}/marchand`, { observe: 'response' });
    }

}
