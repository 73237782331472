import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth-service';
import { ItechpayUser } from '../auth/user.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  @Input() opac: boolean;
  isAuthenticated: boolean;
  currentUser: ItechpayUser;
  currentUserLogin: string;

  constructor(protected router: Router, protected auth: AuthService) { }

  ngOnInit(): void {
    this.currentUserLogin = this.auth.getCurrentUserLogin();
    this.isAuthenticated = this.auth.getCurrentUserLogin() ? true : false;
    this.getCurrentUser();
  }

  login() {
    this.router.navigate(['/login']);
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/accueil']);
  }

  register() {
    this.router.navigate(['/inscription']);
  }

  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  faq() {
    this.router.navigate(['/faq']);
  }

  getCurrentUser() {
    this.auth.getCurrentUser().subscribe(res => {
      this.currentUser = res.body.data;
    }, err => {
      console.error(err);
    });
  }

}
